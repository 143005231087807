
const { client } = require('./auth').default;
 

const getCateoryList = async () => {
    try {
        const response = await client.get(`/category`);
        return response.data;
    } catch (error) {
        console.log('error::', error)
        console.error(error);
    }
}

const getProduct = async (id) => {
    try {
        const response = await client.get(`/products/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

const getProductByCat = async (cat) => {
    try {
        const response = await client.get(`/products/cat/${cat}`);
        let newData = response.data?.filter(el => el.id > 0).sort((a, b) => a.name - b.name )
        return newData;
    } catch (error) {
        console.error(error);
    }
}

export default {
    getProduct,
    getCateoryList,
    getProductByCat
};