import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import SideBar from '../component/sidebar'
import MenuMobile from '../component/menubar';
import MenuLeft from '../component/menu-left';
import apiUser from '../api/user';
import apiCart from '../api/cart';
import global from '../api/global';
import TableOrders from '../component/table-orders';

const MyProfile = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const [orders, setOrders] = useState('');

    const fetchData = async () => {
        try {
            let uuid = JSON.parse(base64_decode(localStorage.getItem('uuid')));
            if (uuid.user === 2) {
                navigate('/login')
            }
            let getUser = await apiUser.get(uuid.user)
            setUser(getUser);

            /**Get Orders */
            let getOrder = await apiCart.getCartList(uuid.user)
            setOrders(getOrder);

        } catch (e) {
            console.error('error fetch::', e)
        }
    }

    /**onload */
    useEffect(() => {
        fetchData().catch(console.error);
    }, [])

    return (
        <div className='container'>
            <main className='container fixed-top bg-white'>
                <MenuLeft />
            </main>
            <div className='row main products'  >
                <div className='col-md-3'>
                    <SideBar />
                </div>
                <div className='col mobile-content'>
                    <div className="row">
                        <div className="col-12 col-sm-4">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">
                                        {t('Personal Profile')} <span className='text-success font-14'> | </span>
                                        <Link to={'/edit/profile'} className="font-14 text-decoration-none">{t('Edit')}</Link>
                                    </h5>
                                    <div className="row">
                                        <div className="col-12">
                                            {user.f_name_th} {user.l_name_th}
                                        </div>
                                        <div className="col-12">
                                            {user.email || 'info@thammasak.co.th'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-8">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h5 className="card-title">
                                                {t('Address Book')} <span className='text-success font-14'> | </span>
                                                <Link to={'/edit/address'} className="font-14 text-decoration-none">{t('Edit')}</Link>
                                            </h5>
                                            <div className="row">
                                                <div className="col-12 text-secondary font-12">{t('DEFAULT SHIPPING ADDRESS')}</div>
                                                <div className="col-12 font-14">
                                                    {user.settings?.address}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col">
                            <h5>{t('Recent Orders')}</h5>
                            <TableOrders orders={orders} />
                        </div>
                    </div>
                </div>
            </div>
            <MenuMobile />
        </div>
    );
}

export default MyProfile;
