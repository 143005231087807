import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import global from '../api/global';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
const moment = require('moment');

function Modal(props) {
    const { t } = useTranslation();
    const NumberFormat = global.NumberFormat;
    const JobStatus = global.JobStatus;

    return (
        <div className="modal fade" id="confirmModal" tabIndex={-1} aria-labelledby="confirmModalLabel" aria-hidden="true">
            <form>
                <div className="modal-dialog modal-dialog-centered">

                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="confirmModalLabel">{t(`Confirm Order`)}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            {t(`Confirm`)} ?
                        </div>
                        <div className="modal-footer">
                            <button type="button"
                                className="btn btn-primary btn-sm"
                                onClick={(el) => props.updateStatus(el, props.order)}
                                data-bs-dismiss="modal"
                            >
                                {t('Confirm')}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Modal;