import { decode as base64_decode, encode as base64_encode } from 'base-64';
import global from './global';
const { client } = require('./auth').default;

const get = async (id) => {
    try {
        const response = await client.get(`/users/${id}`);
        return {
            ...response.data,
            settings: response.data.settings ? JSON.parse(response.data.settings) : []
        };
    } catch (error) {
        console.error(error);
    }
}

const register = async (params) => {
    try {
        let body = {
            "f_name_th": params.name.split(' ')[0] || '',
            "l_name_th": params.name.split(' ')[1] || '',
            "phone": params.mobile,
            "username": params.email,
            "password": params.password,
            "email": params.email,
            "facebook_auth": "",
            "google_auth": "",
            "permissions": JSON.stringify(['user']),
            "rules": "",
            "settings": JSON.stringify({ address: "" }),
            "avatar": "",
            "comp": 1,
            "status": "Active",
            "stock": 0
        }
        console.log('>>> POST', body);
        const response = await client.post(`/users`, body);
        console.log('<<< RES::', response);
        return response.data;
    } catch (error) {
        console.error('error register::', error);
    }
}

const login = async (param) => {
    try {
        let body = {
            "username": param.user,
            "password": param.password
        }
        console.log('>>> POST login', body);
        const response = await client.post(`/login`, body, {
            headers: {
                domain: global.config.domain
            }
        });
        console.log('<<< login RES::', response);
        return response;
    } catch (e) {
        console.error('<<< ERROR LOGIN', e);
        return {
            status: e.status,
            message: e.data?.message || e.message
        }
    }
}

const update = async (params, id) => {
    try {
        let body = {
            ...params,
            settings: Object.keys(params.settings).length ? JSON.stringify(params.settings) : ""
        }
        console.log('>>> PATCH update', body);
        const response = await client.patch(`/users/${id}`, body);
        console.log('<<< update RES::', response);
        return response;
    } catch (e) {
        console.error('<<< ERROR update', e);
        return {
            status: e.status,
            message: e.message
        }
    }
}

export default {
    get,
    register,
    login,
    update
};