import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import SideBar from '../component/sidebar'
import MenuMobile from '../component/menubar';
import Items from '../component/items';
import MenuLeft from '../component/menu-left';
import TopMenu from '../component/top-menu';
import apiUser from '../api/user';
import FormProfile from '../component/form_profile';

const EditProfile = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [user, setUser] = useState({});

    const fetchData = async () => {
        try {
            let uuid = JSON.parse(base64_decode(localStorage.getItem('uuid')));
            if (uuid.user === 2) {
                navigate('/login')
            }
            let getUser = await apiUser.get(uuid.user)
            console.log('getuser::', getUser);
            localStorage.setItem('userInfo', JSON.stringify(getUser));
            setUser(getUser);
        } catch (e) {
            console.error('error fetch::', e)
        }
    }

    /**onload */
    useEffect(() => {
        fetchData().catch(console.error);
    }, [])

    return (
        <div className='container'>
            <main className='container fixed-top bg-white'>
                <MenuLeft />
            </main>
            <div className='row main products'  >
                <div className='col-md-3'>
                    <SideBar />
                </div>
                <div className='col mobile-content'>
                    <div className="row">
                        <div className="col">
                            {t('Profile')}
                        </div>
                    </div>
                    <FormProfile t={t} user={user} setUser={setUser}  className="mt-1" />
                </div>
            </div>
            <MenuMobile />
        </div>
    );
}

export default EditProfile;
