import React from 'react';
import { useTranslation } from 'react-i18next';
import SideBar from '../component/sidebar'
import MenuMobile from '../component/menubar';
import Items from '../component/items';
import MenuLeft from '../component/menu-left';




const Home = () => {

    const { t } = useTranslation();

    document.body.style.backgroundColor = "#fff";

    return (
        <div className='container'>
            <main className='container fixed-top ' >
                <MenuLeft />
            </main>

            <div className='row main products'>
                <div className='col-md-3'>
                    <SideBar />
                </div>
                <div className='col'>
                    <div className='container'>

                        <Items />
                    </div>
                </div>
            </div>
            <MenuMobile />
        </div>
    );
}

export default Home;
