import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAngleLeft,
    faBackward,
    faCircleChevronLeft
} from '@fortawesome/free-solid-svg-icons';
import Waiting from '../component/waiting';
import apiUser from '../api/user';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';


const Login = () => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const [show, setShow] = useState(false);
    const [toastColor, setToastColor] = useState('bg-info');
    const [toastResult, setToastResult] = useState(t('Successful'));

    const navigate = useNavigate();
    const [mobile, setMobile] = useState('+66');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [name, setName] = useState('');
    const [agree, setAgree] = useState(false);

    const clickCheckBox = (event) => { setAgree(event.target.checked); }
    const Sigin = () => { navigate('/', { replace: true }); }

    const isValidEmailAddress = (address) => { return !!address.match(/.+@.+/) }

    const Register = async (data) => {
        try {

            let res = await apiUser.register(data);
            console.log('res::', res);
            if (res.status !== 2) {
                setToastColor('bg-info');
                setToastResult(t('Successful'))
                throw res;
            }
            setLoading(false);

            navigate('/login', { replace: true });
        } catch (e) {
            console.error('error register::', e.message);
            setLoading(false);
            setToastColor('bg-danger');
            setToastResult(t(e.message))
        }
    }

    const Submit = async () => {
        let body = {
            mobile: mobile,
            email: email,
            password: password,
            name: name,
            agree: agree
        }
        if (!isValidEmailAddress(body.email)) {
            console.error('email not correct')
        }
        if (body.email === '' || body.password === '') {
            console.error('email or password is empty')
        }
        if (body.agree) {
            setLoading(true);
            await Register(body);
        }

    }




    const confirmPassword = () => {

    }



    document.body.style.backgroundColor = "#2D318C";

    return (
        <div className='container register'>
            <Waiting loading={loading} />
            <div className="modal fade" id="confirmModal" tabIndex={-1} aria-labelledby="confirmModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header text-primary">
                            <h5 className="modal-title" id="confirmModalLabel">{t('Confirm')}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body text-primary">
                            {t(`confirm register`)}
                        </div>
                        <div className="modal-footer">
                            <button type="button"
                                className="btn btn-primary"
                                onClick={(el) => Submit(el)}
                                data-bs-dismiss="modal"
                            >
                                {t('Confirm')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-center mt-3' >
                <img src="/assets/images/login-logo.png" alt="logo" className='img-fluid' style={{ maxWidth: '20%' }} />
            </div>
            <div className='d-flex justify-content-start ' >
                <Link to={'/login'} className="text-decoration-none text-white">
                    <FontAwesomeIcon icon={faAngleLeft} className="text-white mx-1" style={{ fontSize: 20 }} /> {t('Login')}
                </Link>
            </div>
            <div className="container">
                <div className=" row mb-3">
                    <div className="col-12 col-sm-6">
                        <label htmlFor="email" className="form-label">{t('email')}</label>
                        <input type="email" onChange={e => setEmail(e.target.value)} className="form-control" id="email" placeholder="user@gmail.com" />
                        <span className={`text-danger font-12  ${email === '' || !isValidEmailAddress(email) ? "" : "d-none"}`} >{t('Please input your email')}</span>
                    </div>

                    <div className="col-12 col-sm-6">
                        <label htmlFor="password" className="form-label">{t('password')}</label>
                        <input type="password" onChange={e => setPassword(e.target.value)} className="form-control" id="passwd" placeholder="********" />
                        <span className={`text-danger font-12  ${password === '' ? "" : "d-none"}`} >{t('Please input your password')}</span>
                    </div>

                    <div className="col-12 col-sm-6">
                        <label htmlFor="confirm-password" className="form-label">{t('confirm password')}</label>
                        <input type="password" onChange={e => setRePassword(e.target.value)} className="form-control" id="confirm-password" placeholder="********" />
                        <span className={`text-danger font-12  ${password !== rePassword ? "" : "d-none"}`} >{t('Password and confirm password does not match')}</span>
                    </div>

                    <div className="col-12 col-sm-6">
                        <label htmlFor="mobile" className="form-label">{t('mobile number')}</label>
                        <PhoneInput
                            value={mobile}
                            onChange={setMobile}
                            className="form-control"
                            defaultCountry="TH"
                            id="mobile"
                        />

                    </div>

                    <div className="col-12 col-sm-6">
                        <label htmlFor="fullname" className="form-label">{t('name')} {t('lastname')} (กรุณาระบุตามบัตรประชาชน)</label>
                        <input type="text" onChange={e => setName(e.target.value)} className="form-control" id="fullname" placeholder={t('name') + ' ' + t('lastname')} />
                    </div>

                    <div className="col-12 col-sm-6 mt-4">
                        <input
                            type="button"
                            className='btn btn-primary mt-2 w-100'
                            value={t('next')}
                            data-bs-toggle="modal"
                            data-bs-target="#confirmModal"
                        />
                    </div>

                    <div className="col-12 col-sm-6">
                        <div className="d-flex  mt-2">
                            <input
                                className='form-check-input checkbox-lg'
                                style={{ fontSize: 20, width: 30 }}
                                type={'checkbox'}
                                name="agree"
                                id="agree"
                                value={agree}
                                onChange={clickCheckBox}
                            />
                            <label htmlFor="agree" className="form-label font-10 mx-2">
                                ยินยอมให้ บจก. ธรรมศักดิ์ บันทึกและประมวลผลข้อมูลเพื่อใช้ในการดำเนินสัญญาซื้อขายระหว่างท่านและร้านค้า
                                <Link to={'/agreement'} className='text-decoration-none'> ข้อตกลงและเงื่อนไข</Link>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-end" className=" ">
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                    <Toast.Header className={toastColor}>
                        <strong className="me-auto text-white">{t('Actions')}</strong>
                        <small className="text-muted"> </small>
                    </Toast.Header>
                    <Toast.Body>{toastResult}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    );
}

export default Login;
