import React, { useState } from 'react';
import apiUser from '../api/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import _ from 'lodash';

function FormProfile({ user, setUser, t, className }) {

    const [show, setShow] = useState(false);
    const [toastColor , setToastColor] = useState('bg-info');
    const [toastResult, setToastResult] = useState(t('Successful'));

    const saveUpdate = async (element, user) => {
        try {
            //let body = _.omit(user, "settings", "permissions");
            let res = await apiUser.update(user, user.id);
            console.log('update result::', res);
            localStorage.setItem('userInfo', JSON.stringify(user));
            setShow(true);
            if(res.data?.status === 2){
                setToastColor('bg-info');
                setToastResult(t('Successful'))          
            } else{
                setToastColor('bg-danger');
                setToastResult(t('Fail'))  
            }
        } catch (e) {
            console.error('error update::', e);
        }
    }

    const changeSetting = (data) => {
        let newData = {
            ...user,
            settings: {
                ...data
            }
        }
        setUser(newData);
    }


    console.log('user::', user);
    return (
        <div className={`container  ${className}`}>
            <div className="modal fade" id="confirmModal" tabIndex={-1} aria-labelledby="confirmModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">

                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="confirmModalLabel">{t(`Confirm save`)}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            {t(`Confirm`)} ?
                        </div>
                        <div className="modal-footer">
                            <button type="button"
                                className="btn btn-primary btn-sm"
                                onClick={(el) => saveUpdate(el, user)}
                                data-bs-dismiss="modal"
                            >
                                {t('Confirm')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row ">
                <div className="col-12 col-sm-6">
                    <div className="input-group mt-4">
                        <div className="input-group-prepend">
                            <span className="input-group-text">{t('name')}</span>
                        </div>
                        <input
                            type="text"
                            aria-label="First name"
                            name="f_name_th"
                            value={user.f_name_th || ''}
                            onChange={(e) => setUser({ ...user, f_name_th: e.target.value })}
                            className="form-control"
                            placeholder={t('first name')}
                            id="first-name"
                        />
                        <input
                            type="text"
                            aria-label="Last name"
                            name="l_name_th"
                            value={user.l_name_th || ''}
                            onChange={(e) => setUser({ ...user, l_name_th: e.target.value })}
                            className="form-control"
                            placeholder={t('last name')}
                            id="last-name"
                        />
                    </div>
                </div>
                <div className="col">
                    <div className="row mobile-content-row">
                        <div className="col">
                            <label htmlFor='email'> {t('email')}</label>
                            <input
                                type={'text'}
                                name="email"
                                value={user.email || ''}
                                onChange={(e) => setUser({ ...user, email: e.target.value })}
                                className="form-control"
                                placeholder="info@thammasak.co.th"
                                id="email"
                            />
                        </div>
                        <div className="col">
                            <label htmlFor='phone'> {t('mobile')}</label>
                            <input
                                type={'text'}
                                name="phone"
                                value={user.phone || ''}
                                onChange={(e) => setUser({ ...user, phone: e.target.value })}
                                className="form-control"
                                placeholder="0888888888"
                                id="phone"
                            />
                        </div>
                    </div>
                </div>
            </div>


            <div className="row mt-3">                
                <div className="col">
                    <div className="row mobile-content-row">
                        <div className="col">
                            <label htmlFor='cust_name'> {t('Bill Name')}</label>
                            <input
                                type={'text'}
                                name="cust_name"
                                value={user.settings?.cust_name || ''}
                                onChange={(e) => setUser({ ...user, settings: { ...user.settings , cust_name:  e.target.value } })}
                                className="form-control"
                                placeholder="info@thammasak.co.th"
                                id="cust_name"
                            />
                        </div>
                        <div className="col">
                            <label htmlFor='cust_cid'> {t('Citizen ID')}</label>
                            <input
                                type={'text'}
                                name="cust_cid"
                                value={user.settings?.cust_cid || ''}
                                onChange={(e) => setUser({ ...user, settings: { ...user.settings , cust_cid:  e.target.value } })}
                                className="form-control"
                                placeholder="0888888888"
                                id="cust_cid"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col text-end">
                    <button type='button'
                        className='btn btn-primary btn-sm'
                        data-bs-toggle="modal"
                        data-bs-target="#confirmModal"
                    >
                        <FontAwesomeIcon icon={faFloppyDisk} /> Save</button>
                </div>
            </div>
            <ToastContainer position="top-end" className=" ">
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                    <Toast.Header className={toastColor}>                        
                        <strong className="me-auto text-white">{t('Actions')}</strong>
                        <small className="text-muted"> </small>
                    </Toast.Header>
                    <Toast.Body>{toastResult}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    )
}

export default FormProfile;