import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';

import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';

import apiUser from '../api/user';
import uuid from 'react-uuid';
import jwt_decode from "jwt-decode";
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

import producton from '../config/production';
import user from '../api/user';

const responseFacebook = (response) => {
    console.log(response);
}

const componentClicked = (res) => {
    console.log('res::', res);
}

const responseGoogle = (response) => {
    console.log(response);
}

const responseFail = (response) => {
    console.warn(response)
}


const Login = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [toastColor, setToastColor] = useState('bg-info');
    const [toastResult, setToastResult] = useState(t('Successful'));

    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const Sigin = async () => {
        try {
            let body = {
                user: user,
                password: password
            }
            let res = await apiUser.login(body);
            setShow(true);
            if (res.data?.token) {
                setErrorMessage('')

                
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('uuid', base64_encode(JSON.stringify({
                    uuid: uuid(),
                    user: jwt_decode(res.data.token)['id'],
                    name: user
                })));

                let getUser = await apiUser.get(jwt_decode(res.data.token)['id']);
                localStorage.setItem('userInfo', JSON.stringify(getUser));

                setToastColor('bg-info');
                setToastResult(t('Successful'))

                navigate('/', { replace: true });
            } else {
                setErrorMessage("username or password incorrect");
                throw res;
            }

        } catch (e) {
            setShow(true);
            console.error('error::', e);
            setErrorMessage("username or password incorrect");
            setToastColor('bg-danger');
            setToastResult(t("username or password incorrect"))
        }

    }

    document.body.style.backgroundColor = "#2D318C";

    return (
        <div className='container login'>
            <div className='d-flex justify-content-center mt-3' >
                <img src="/assets/images/login-logo.png" alt="logo" className='img-fluid' style={{ maxWidth: '50%' }} />
            </div>
            <div className='d-flex justify-content-center ' >
                <img src='/assets/images/login-img-w300.jpg' alt="logo" className='img-fluid' />
            </div>

            <div className="mb-3">
                <label htmlFor="user" className="form-label">{t('login_text_info')}</label>
                <input type="user" className="form-control" onChange={e => setUser(e.target.value)} id="user" placeholder="username" />
                <span className={`text-danger font-12  ${user === '' ? "" : "d-none"}`} >{t('Please input username')}</span>
                <input type="password" className="form-control mt-3" onChange={e => { setErrorMessage(''); return setPassword(e.target.value) }} id="passwd" placeholder="password" />
                <span className={`text-danger font-12  ${password === '' ? "" : "d-none"}`} >{t('Please input password')}</span>
                <div className={`text-danger font-12  ${errorMessage !== '' ? "" : "d-none"}`} >{t(errorMessage)}</div>

                {/* <div className='mt-2'>
                    <GoogleLogin
                        clientId={producton.google.client_id}
                        buttonText="Google Login"
                        onSuccess={responseGoogle}
                        onFailure={responseFail}
                        cookiePolicy={'single_host_origin'}
                    />
                </div>
                <div className="mt-2">
                    <FacebookLogin
                        appId="745199906752851"
                        autoLoad={true}
                        fields="name,email,picture"
                        onClick={componentClicked}
                        callback={responseFacebook} />
                </div> */}
                <div>
                    <input type="button" className='btn btn-primary mt-2  btn-sm' value={t('sigin')} onClick={Sigin} />
                </div>
                <p className='mt-3'>หากยังไม่ได้สมัครสมาชิก กดลงทะเบียน</p>
            </div>


            <div className="  d-flex justify-content-end">
                <Link to={`/register`} className="btn btn-success btn-sm mt-1 mb-2" >{t('register')} </Link>
            </div>
            <ToastContainer position="top-end" className=" ">
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                    <Toast.Header className={toastColor}>
                        <strong className="me-auto text-white">{t('Actions')}</strong>
                        <small className="text-muted"> </small>
                    </Toast.Header>
                    <Toast.Body>{toastResult}</Toast.Body>
                </Toast>
            </ToastContainer>

        </div>
    );
}

export default Login;
