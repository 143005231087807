import React from 'react';
import { useTranslation } from 'react-i18next';
import SideBar from '../component/sidebar'
import MenuMobile from '../component/menubar';
import Items from '../component/items';
import MenuLeft from '../component/menu-left';
import TopMenu from '../component/top-menu';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleChevronLeft
} from '@fortawesome/free-solid-svg-icons';

const MyProfile = () => {

    const { t } = useTranslation();
const navigate = useNavigate();
    const goBack = () => {
        console.log('data')
        navigate(-1)
    }
    document.body.style.backgroundColor = "#fff";

    return (
        <div className='container'>
             <div className='fixed-top disabled-print' style={{ left: '5%', top: '2%' }}>
                <FontAwesomeIcon icon={faCircleChevronLeft} onClick={goBack} className="text-primary" style={{ fontSize: 20 }} />
            </div>
            <div className='row main products'  >
                 
                <div className='col mt-5'>
                    <div className="row">
                        <div className="col">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Special title treatment</h5>
                                    <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                    <a href="#" className="btn btn-primary">Go somewhere</a>
                                </div>
                            </div>
                        </div>
                         
                    </div>
                </div>
            </div>
            <MenuMobile />
        </div>
    );
}

export default MyProfile;
