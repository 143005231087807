import React, { useState } from 'react';
import apiUser from '../api/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

function FormAddress({ user, setUser, t, className }) {

    const [show, setShow] = useState(false);
    const [toastColor, setToastColor] = useState('bg-info');
    const [toastResult, setToastResult] = useState(t('Successful'));
    const [setting, setSetting] = useState(user);

    const saveUpdate = async (element, user) => {
        try {
            console.log('update user::', user);
            let res = await apiUser.update(user, user.id);
            console.log('update result::', res);
            localStorage.setItem('userInfo', JSON.stringify(user));
            setShow(true);
            if (res.data?.status === 2) {
                setToastColor('bg-info');
                setToastResult(t('Successful'))
            } else {
                setToastColor('bg-danger');
                setToastResult(t('Fail'))
            }
        } catch (e) {
            console.error('error update::', e);
        }
    }

    const changeAddress = (data) => {
        let newData = {
            ...user,
            settings: {
                ...data
            }
        }
        setUser(newData);
    }

    return (
        <div className={`container  ${className}`}>
            <div className="modal fade" id="confirmModal" tabIndex={-1} aria-labelledby="confirmModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">

                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="confirmModalLabel">{t(`Confirm save`)}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            {t(`Confirm`)} ?
                        </div>
                        <div className="modal-footer">
                            <button type="button"
                                className="btn btn-primary btn-sm"
                                onClick={(el) => saveUpdate(el, user)}
                                data-bs-dismiss="modal"
                            >
                                {t('Confirm')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row ">
                <div className="col-12  ">
                    <div className="input-group mt-4">
                        <label htmlFor='Address' className=''> {t('Address')}  </label>
                        <div className='w-100'>
                            <textarea
                                type={'text'}
                                name="Address"
                                value={user.settings?.address}
                                onChange={(e) => changeAddress({ ...user.settings, address: e.target.value })}
                                className="form-control"
                                id="Address"
                            />
                        </div>
                    </div>
                     
                </div>

            </div>
            <div className="row mt-4">
                <div className="col text-end">
                    <button type='button'
                        className='btn btn-primary btn-sm'
                        data-bs-toggle="modal"
                        data-bs-target="#confirmModal"
                    >
                        <FontAwesomeIcon icon={faFloppyDisk} /> Save</button>
                </div>
            </div>
            <ToastContainer position="top-end" className=" ">
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                    <Toast.Header className={toastColor}>
                        <strong className="me-auto text-white">{t('Actions')}</strong>
                        <small className="text-muted"> </small>
                    </Toast.Header>
                    <Toast.Body>{toastResult}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    )
}

export default FormAddress;