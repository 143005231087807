import { decode as base64_decode, encode as base64_encode } from 'base-64';

const { client } = require('./auth').default;

const get = async (id) => {
    try {
        const response = await client.get(`/v1/company/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}
 

export default {
    get
};