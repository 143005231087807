import { t } from "i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendarAlt
} from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from "react";
import Template2 from "./template-cart2";
import global from "../api/global";


const Template = ({ item, updateLine, checkedClick, setCartList, itemCart }) => {
    let template;

    //console.log('item template 1::', props)
    switch (item.category) {
        case 2:
            template = <Template2
                item={item}
                updateLine={updateLine}
                checkedClick={checkedClick}
                setCartList={setCartList}
                itemCart={itemCart}
            />;
            break;

        default:
            template = <Template2
                item={item}
                updateLine={updateLine}
                checkedClick={checkedClick}
                setCartList={setCartList}
                itemCart={itemCart}
            />;
            break;
    }
    return template;
}


const Template1 = ({ item: data, updateLine, checkedClick, setCartList, itemCart }) => {
    const [item, setItems] = useState([]);


    const handleChange = async (event) => {
        let newBody = {
            ...item,
            [event.target.name]: event.target.value
        }
        setItems(newBody);

        itemCart.map(el => {
            if (el.uuid === item.uuid) {
                el.date_amount = parseFloat(event.target.value);
            }
        })
        /**
         * Update item to parent
         */
        setCartList(itemCart);
        localStorage.setItem('cart-list', JSON.stringify(itemCart));
        updateLine()
    };

    const NumberFormat = (value) => Number(value).toLocaleString(navigator.language, { minimumFractionDigits: 2 });

    const clickCheckBox = (event) => {
        /** refresh data after click checkbox */
        setItems({
            ...item,
            checked: event.target.checked
        });
        return checkedClick(event)
    }

    useEffect(() => {
        setItems(data);
    }, [])

    console.log('item::', item);
    return (
        <div className="mb-2 font-12">
            <div className="row">
                <div className="col">
                    ลูกค้าสามารถเลือกเช่าเป็นชุด หรือเลือกชิ้นแยกตามชิ้นงานได้ตามวัตถุประสงค์ของการใช้งานได้อย่างสะดวก
                </div>
            </div>
            <div className="row  mb-1">
                <h6 className="mt-2">รายการเช่าเป็นชุด</h6>
                <div className='col-4 '>
                    <label htmlFor={item.id}>
                        <img
                            src={item.img}
                            alt={item.title}
                            className="img-fluid"
                            style={{ maxWidth: '100%' }}

                        />
                    </label>
                </div>
                <div className="col">
                    <div className="row">
                        <div className="col">
                            <input
                                className='form-check-input checkbox-lg'
                                type={'checkbox'}
                                name={item.name}
                                checked={item.checked || false}
                                onChange={clickCheckBox}
                                id={item.uuid}
                            />

                            <label className="ms-1" htmlFor={item.uuid}>{item.title} {item.name}</label>
                        </div>
                        <div className="col-4  text-end">
                            <input type="text" className="btn btn-secondary btn-sm disabled w-100" defaultValue={item.price && NumberFormat(item.price)} />
                        </div>
                        <div className="col-2">
                            {item.unit || t('unit')}
                        </div>
                    </div>
                    <div className="row my-1">
                        <div className="col">
                            <FontAwesomeIcon icon={faCalendarAlt} />
                            <span className="ms-1 font-10">วันที่เริ่ม</span>
                            <span className="ms-1 font-9">MM/DD/YY</span>
                        </div>
                        <div className="col">
                            <FontAwesomeIcon icon={faCalendarAlt} />
                            <span className="ms-1 font-10">วันที่สิ้นสุด</span>
                            <span className="ms-1 font-9">MM/DD/YY</span>
                        </div>
                    </div>

                    <div className="row my-1">
                        <div className="col">
                            <span className="ms-1">จำนวนวันที่เช่า</span>
                        </div>
                        <div className="col-4 text-end">
                            <input type="text" name="date_amount"
                                onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) event.preventDefault(); }}
                                className="form-control form-control-sm text-end"
                                value={Number(item.date_amount || 0)}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-2">
                            วัน
                        </div>
                    </div>

                    <div className="row my-1">
                        <div className="col">
                            <span className="ms-1">คำนวนราคา</span>
                        </div>
                        <div className="col-4 text-end">
                            <input type="text" name="total_price" readOnly="readonly" className="form-control form-control-sm text-end" value={Number(item.date_amount * item.price || 0)} onChange={handleChange} />
                        </div>
                        <div className="col-2">
                            บาท
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

 

export default {
    Template,
    Template1,
};