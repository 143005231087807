import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SideBar from '../component/sidebar'
import MenuMobile from '../component/menubar';
import Items from '../component/items';
import MenuLeft from '../component/menu-left';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import apiCart from '../api/cart';
import apiCompany from '../api/company';
import apiUser from '../api/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBackward, faCheckCircle, faCircleChevronLeft, faFloppyDisk, faPrint, faEdit,
    faPowerOff,
    faEye
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate, Link } from 'react-router-dom';
import global from '../api/global';
import TableOrders from '../component/table-orders';
const moment = require('moment');


const Orders = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [orders, setOrders] = useState('');


    const fetchData = async () => {
        try {
            let uuid = JSON.parse(base64_decode(localStorage.getItem('uuid')));

            if(uuid.user === 2){
                navigate('/login')
            }
            let getOrder = await apiCart.getCartList(uuid.user)
            setOrders(getOrder);
        } catch (e) {
            console.error('error fetch::', e)
        }
    }

    useEffect(() => {
        fetchData().catch(console.error);
    }, [])

    console.log('orders::', orders);
    return (
        <div className='container mb-2'>
            <main className='container fixed-top bg-white'>
                <MenuLeft />               
            </main>

            <div className='row main products'  >
                <div className='col-md-3'>
                    <SideBar />
                </div>
                <div className='col'>
                    <div className='container'>
                        <h3 className='font-font-weight-bold text-center mt-2'>{t('My Orders')}</h3>
                        {t('Order List')}
                        <div className='mt-1' style={{ marginBottom: 90 }}>
                            <TableOrders orders={orders}/>                             
                        </div>
                    </div>
                </div>
            </div>
            <MenuMobile />

        </div>
    );
}

export default Orders;
