import React from 'react';
import { useTranslation } from 'react-i18next';
import SideBar from '../component/sidebar'
import MenuMobile from '../component/menubar';
import Items from '../component/items';
import MenuLeft from '../component/menu-left';




const MyAccount = () => {

    const { t } = useTranslation();

    document.body.style.backgroundColor = "#fff";

    return (
        <div className='container'>
            <main className='container fixed-top bg-white'>
                <MenuLeft />
                
            </main>

            <div className='row main products' style={{marginTop:180}}>
                <div className='col-md-2'>
                    <SideBar />
                </div>
                <div className='col'>
                     
                </div>
            </div>
            <MenuMobile />
        </div>
    );
}

export default MyAccount;
