import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SideBar from '../component/sidebar'
import MenuMobile from '../component/menubar';
import Items from '../component/items';
import MenuLeft from '../component/menu-left';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import apiCart from '../api/cart';
import apiCompany from '../api/company';
import apiUser from '../api/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faCheckCircle, faCircleChevronLeft, faFloppyDisk, faPrint } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from '../component/modal';
import global from '../api/global';

const moment = require('moment');


const Order = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const [company, setCompany] = useState([]);
    const [order, setOrder] = useState('');
    const [user, setUser] = useState([]);
    const [lineTotal, setLineTotal] = useState(0);
    const [vat, setVat] = useState(0);
    const [total, setTotal] = useState(0);
    const [countLine, setCountLine] = useState(0);

    const fetchData = async () => {

        let getOrder = await apiCart.getCartById(id)
        /**
         * Set Company
         */
        let resCompany = await apiCompany.get(getOrder.header.comp);
        let comp = {
            ...resCompany,
            data: typeof resCompany.data === 'string' ? JSON.parse(JSON.parse(resCompany.data)) : resCompany.data
        };
        setCompany(comp);

        /**
         * Set User
         */
        let getUser = await apiUser.get(getOrder.header.user);
        let usr = {
            ...getUser,
            settings: typeof getUser.settings === 'string' ? JSON.parse(getUser.settings) : getUser.settings
        };
        setUser(usr);

        /**
         * Set Order
         */
        let orders = {
            ...getOrder,
            yyyy_th: Number(moment(order.header?.start_date).format('YYYY')) + 543,
            start_date_th: moment(order.header?.start_date).add(543, 'year')
        }
        console.log('header >>', orders)
        let lineAmount = 0;
        orders.line?.forEach(item => {
            lineAmount += item.price * item.date_amount;
        })
        setLineTotal(lineAmount);

        /**
         * Total
         */
        let vat7 = Number(lineAmount - orders.header?.discount) * 7 / 100;
        let totals = Number(lineAmount + vat7);

        setVat(vat7);
        setTotal(totals);

        setOrder(orders);
        setCountLine(orders.line.length)

    }

    useEffect(() => {
        fetchData().catch(console.error);
    }, [])

    const goBack = () => {
        console.log('data')
        navigate('/orders')
    }

    const NumberFormat = global.NumberFormat;


    const confirmOrder = (el, item) => {
        //updateStatus(el, item);
    }

    const updateStatus = async (element, data) => {
        console.log('confirm order::', data);
        try {
            let confirm = await apiCart.confirm(data.header.id)
            console.log('confirmed::', confirm);
            if (confirm.status === 2) {
                localStorage.removeItem('orderCache');
                navigate(`/order/${data.header.id}`);
            }
        } catch (e) {
            console.error('Error::', e);
        }

    }

    document.body.style.backgroundColor = "#fff";
    console.log('company::', typeof company, company);
    console.log('order::', order.line);
    return (
        <div className='container mb-2'>
            <div className='fixed-top disabled-print' style={{ left: '5%', top: '2%' }}>
                <FontAwesomeIcon icon={faCircleChevronLeft} onClick={goBack} className="text-primary" style={{ fontSize: 20 }} />
            </div>
            <h3 className='font-font-weight-bold text-center mt-2'>{t('SaleQuotation')}</h3>
            <div className="row mt-5">
                <div className="col-3 text-center">
                    <img src={`/assets/images/${company.img}`} alt="thammasak-icon" className='img-fluid' minwidth={100} maxwidth={150} />
                </div>
                <div className="col">
                    <div className="row">
                        <div className="col font-11">
                            {company.name}
                        </div>
                        <div className="col font-9">
                            website: {company.website}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col font-11">
                            {company.name_en}
                        </div>
                        <div className="col font-9">
                            email: {company.data?.email}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div style={{ borderTop: '1px solid #ccc', width: '100%', height: 1 }}></div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col font-9">
                            {company.address}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col font-9">
                            {company.data?.add_en}
                        </div>
                    </div>
                </div>
            </div>


            <div className="row mt-3">
                <div className="col">
                    <span className='font-11'>{t('Number')} </span>
                    <span className='font-font-weight-bold font-11'>
                        <b>{order.header?.document_no}</b>
                    </span>
                </div>
                <div className="col text-center font-11">{t('SaleQuotation')}</div>
                <div className="col text-end font-11">
                    {t('PageNo')} 1/1
                </div>
            </div>


            <div className='mt-2'>

                <div className="row font-11">
                    <div className="col-3 col-sm-2 text-end">
                        <span className=''>{t('customer')} :</span>
                    </div>
                    <div className="col" style={{ borderBottom: "1px solid #ccc" }}>
                        {order.header?.cust_name}
                    </div>
                    <div className="col-4 col-sm-3">
                        <div className='row'>
                            <div className="col-7 text-end">
                                {t('rentDate')} :
                            </div>
                            <div className="col p-0">
                                {moment(order.header?.start_date).format('DD/MM/YY')}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row font-11">
                    <div className="col-3 col-sm-2 text-end">
                        <span className=''>{t('citizenId')} :</span>
                    </div>
                    <div className="col" style={{ borderBottom: "1px solid #ccc" }}>
                        {order.header?.cust_cid}
                    </div>
                    <div className="col-4 col-sm-3">
                        <div className='row'>
                            <div className="col-7 text-end">
                                {t('time')} :
                            </div>
                            <div className="col p-0 text-start">
                                {moment(order.header?.start_date).format('HH:mm')}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row font-11">
                    <div className="col-3 col-sm-2 text-end">
                        <span className=''>{t('phone')} :</span>
                    </div>
                    <div className="col-5 col-sm-7" style={{ borderBottom: "1px solid #ccc" }}>
                        {order.header?.cust_mobile}
                    </div>
                    <div className="col text-start font-10 ">
                        {t('dateOfReturn')} :
                    </div>
                </div>


                <div className="row font-11">
                    <div className="col-3 col-sm-2 text-end">
                        <span className=''>{t('project/place')}  :</span>
                    </div>
                    <div className="col" style={{ borderBottom: "1px solid #ccc" }}>
                        {order.header?.cust_place}
                    </div>
                    <div className="col-4 col-sm-3">
                    <div className='row'>
                            <div className="col-7 text-end">
                                {t('rentDate')} :
                            </div>
                            <div className="col p-0">
                                {moment(order.header?.end_date).format('DD/MM/YY')}
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row font-11">
                    <div className="col-3 col-sm-2 text-end">
                        <span className=''>{t('entity/place')} :</span>
                    </div>
                    <div className="col" style={{ borderBottom: "1px solid #ccc" }}>
                        {order.header?.cust_project}
                    </div>
                    <div className="col-4 col-sm-3">
                    <div className='row'>
                            <div className="col-7 text-end">
                                {t('time')} :
                            </div>
                            <div className="col p-0 text-start">
                                {moment(order.header?.end_date).format('HH:mm')}
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row font-11">
                    <div className="col-3 col-sm-2 text-end">
                        <span className=''>{t('contact person')} :</span>
                    </div>
                    <div className="col-5 col-sm-7" style={{ borderBottom: "1px solid #ccc" }}>
                        {order.header?.cust_contact}
                    </div>
                    <div className="col text-end">
                        <div className='row'>
                            <div className="col-7 text-end font-10">
                                {t('all date rent')} :
                            </div>
                            <div className="col p-0 text-start">
                                {countLine}
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-4'>
                <table className='table table-bordered font-10'>
                    <thead>
                        <tr>
                            <th width={'2%'} className="text-center">No.</th>
                            <th className="text-center">{t('Detail')}</th>
                            <th width={'15%'} className="text-center">{t('Qty')}</th>
                            <th width={'18%'} className="text-center">{t('Price/Unit')}</th>
                            <th width={'15%'} className="text-center">{t('date amount')}</th>
                            <th width={'16%'} className="text-center">{t('total (baht)')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {

                            order.line?.map((item, index) => {

                                return (<tr key={item.id}>
                                    <td> {index + 1} </td>
                                    <td> {item.name} </td>
                                    <td className="text-end"> {parseFloat(item.date_amount)} </td>
                                    <td className="text-end"> {NumberFormat(item.price, 0)}</td>
                                    <td className="text-end"> {parseFloat(item.date_amount)}</td>
                                    <td className="text-end"> {NumberFormat(item.price * item.date_amount, 0)} </td>

                                </tr>)
                            })
                        }


                    </tbody>
                </table>
            </div>

            <div className='mt-3 ' style={{ marginBottom: 90 }}>
                <div className="row">
                    <div className="col">

                    </div>
                    <div className="col-7 col-sm-6 font-12">
                        {t('total rent')}
                        <table className='table table-bordered border-red'>
                            <tbody>
                                <tr>
                                    <td className='text-end'>
                                        ยอดรวม (บาท)
                                    </td>
                                    <td className='text-end'>
                                        {NumberFormat(lineTotal)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-end'>
                                        ส่วนลดพิเศษ (บาท)
                                    </td>
                                    <td className='text-end'>
                                        {NumberFormat(order.header?.discount)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-end'>
                                        ยอดหลังหักส่วนลด (บาท)
                                    </td>
                                    <td className='text-end'>
                                        {NumberFormat(lineTotal - order.header?.discount)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-end'>
                                        ภาษีมูลค่าเพิ่ม 7 % (บาท)
                                    </td>
                                    <td className='text-end'>
                                        {NumberFormat(vat)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-end'>
                                        รวมยอดทั้งหมด (บาท)
                                    </td>
                                    <td className='text-end'>
                                        {NumberFormat(total)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className='d-flex justify-content-center ' style={{ marginBottom: 90 }}>
                <button className='btn btn-success btn-sm mx-1 disabled-print' onClick={() => window.print()}>
                    <FontAwesomeIcon icon={faPrint} /> Print ใบเสนอราคา
                </button>

            </div>


            <MenuMobile />
        </div>
    );
}

export default Order;
