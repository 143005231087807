import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages/home';
import Products from './pages/products';
import Detail from './pages/detail';
import NotFound from './pages/NotFound';
import Login from './pages/login';
import Cart from './pages/cart';
import Quotation from './pages/quotation';
import Order from './pages/order';
import Orders from './pages/orders';
import Account from './pages/account';
import MyProfile from './pages/profile';
import Register from './pages/register';
import Agreement from './pages/agreement';
import Signout from './pages/signout';
import EditProfile from './pages/profile_edit';
import EditAddress from './pages/address_edit';
import Privilage from './pages/privilage';
import About from './pages/about';
import Payment from './pages/payment';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products/:id/:name" element={<Products />} />
        <Route path="/product/:id/:name" element={<Detail/>}  />
        <Route path="/quotation/:id" element={<Quotation />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/account" element={<Account />} />
        <Route path="/profile" element={<MyProfile />} />
        <Route path="/edit/profile" element={<EditProfile />} />
        <Route path="/edit/address" element={<EditAddress />} />
        <Route path="/order/:id" element={<Order />} />
        <Route path="/register" element={<Register />} />
        <Route path="/agreement" element={<Agreement />} />
        <Route path="/privilege" element={<Privilage />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/about" element={<About/>} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signout" element={<Signout />} />
        <Route path="*" element={<NotFound/>} />
      </Routes>
    </Router>
  );
}

export default App;
