import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import MenuMobile from '../component/menubar';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import apiCart from '../api/cart';
import apiCompany from '../api/company';
import apiUser from '../api/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircleChevronLeft, faFloppyDisk, faPrint } from '@fortawesome/free-solid-svg-icons';
import DatePicker, { setDefaultLocale } from "react-datepicker";
import global from '../api/global';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const Quotations = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [company, setCompany] = useState([]);
    const [order, setOrder] = useState('');
    const [header, setHeader] = useState({});
    const [user, setUser] = useState([]);
    const [lineTotal, setLineTotal] = useState(0);
    const [vat, setVat] = useState(0);
    const [total, setTotal] = useState(0);

    const fetchData = async () => {
        try {


            let cacheOrder = localStorage.getItem("orderCache") ? JSON.parse(base64_decode(localStorage.getItem("orderCache"))) : [];

            let thisOrder = localStorage.getItem("order") ? JSON.parse(localStorage.getItem("order")) : [];
            console.log('this order::', thisOrder);
            /**
             * Set Company
             */
            //console.log('cart cache::', cacheOrder)
            let resCompany = await apiCompany.get(thisOrder.header?.comp);
            let comp = {
                ...resCompany,
                data: typeof resCompany.data === 'string' ? JSON.parse(JSON.parse(resCompany.data)) : resCompany.data
            };
            console.log('comp::', comp)
            setCompany(comp);

            /**
             * Set User
             */
            let uuid = JSON.parse(base64_decode(localStorage.getItem('uuid')));
            console.log('uuid::', typeof uuid, uuid);
            let getUser = await apiUser.get(uuid.user);
            let usr = {
                ...getUser,
                settings: typeof getUser.settings === 'object' && getUser.settings.length ? JSON.parse(getUser.settings) : getUser.settings
            };
            console.log('Get User::', usr);
            setUser(usr);

            /**
             * Set Order
             */
            //let cart = await apiCart.getCartById(thisOrder.header?.id);
            let cart = thisOrder;
            console.log('cart from cached::', cart);
            let orders = {
                ...cart,
                yyyy_th: Number(moment(order.header?.start_date).format('YYYY')) + 543,
                start_date_th: moment(order.header?.start_date).add(543, 'year')
            }
            setOrder(orders);

            let lineAmount = 0;
            let totalAmount = 0;
            orders.line?.forEach(res => {
                let item = res.data;
                lineAmount += item.price * item.date_amount;
                totalAmount += parseFloat(item.date_amount);
            })
            setLineTotal(lineAmount);


            /**
             * Total
             */
            let totalDiscount = Number(orders.header?.discount || 0);
            let vat7 = Number(lineAmount - totalDiscount) * 7 / 100;
            let totals = Number(lineAmount + vat7);

            setVat(vat7);
            setTotal(totals);


            if (localStorage.getItem('header')) {
                let thisHeader = JSON.parse(localStorage.getItem('header'));
                orders.header = {
                    ...orders.header,
                    cust_name: thisHeader.cust_name,
                    cust_mobile: thisHeader.cust_mobile,
                    cust_cid: thisHeader.cust_cid,
                    cust_place: thisHeader.cust_place,
                    cust_project: thisHeader.cust_project,
                    cust_contact: thisHeader.cust_contact
                }
            }

            let mockupHeader = {
                ...orders.header,
                document_no: "R" + moment().format('MMDD').toString() + id,
                start_date: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                //start_time: moment().format('HH:mm'),
                end_date: moment().format('YYYY-MM-DDThh:mm:ss.SSSZ'),
                total_amount: totalAmount,
                total_discount: totalDiscount,
                //lineAmount: lineAmount,
                vat: vat7,
                amount: totals,
                items: JSON.stringify(orders.line)
            }
            setHeader(mockupHeader);
            localStorage.setItem('header', JSON.stringify(mockupHeader))

        } catch (e) {
            console.error('error fetch::', e)
        }
    }


    const changeHeader = (data) => {
        let mockupHeader = {
            ...header,
            ...data
        }
        setHeader(mockupHeader);
        console.log('update header data>>', data);
        localStorage.setItem('header', JSON.stringify(mockupHeader))
    }

    useEffect(() => {
        fetchData().catch(console.error);
    }, [])

    const goBack = () => {
        console.log('data')
        navigate(-1)
    }

    const NumberFormat = (value, digit = 2) => Number(value).toLocaleString(navigator.language, { minimumFractionDigits: digit });


    const confirmOrder = (el, item) => {
        //updateStatus(el, item);
    }

    const updateStatus = async (element, data) => {
        console.log('confirm order::', data);
        try {
            let id = data.id;
            delete data.id;
            let confirm = await apiCart.confirm(data, id)
            console.log('confirmed::', confirm);
            if (confirm.status === 2) {
                localStorage.removeItem('orderCache');
                /**
                 * Clear cache ลบเฉพาะอันที่เลือกไปแล้ว
                 *  
                 */

                let newData = [];
                order.line?.forEach(line => {
                    let orderLine = JSON.parse(localStorage.getItem('cart-list'));
                    newData = orderLine.filter(el => el.id !== line.data.item);
                    localStorage.setItem('cart-list', JSON.stringify(newData))
                });



                navigate(`/order/${id}`);
            }
        } catch (e) {
            console.error('Error::', e);
        }

    }

    const print = () => {
        window.print();
    }

    return (
        <div className='container mb-2'>
            <div>
                <div className="modal fade" id="confirmModal" tabIndex={-1} aria-labelledby="confirmModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">

                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="confirmModalLabel">{t(`Confirm Order`)}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                {t(`Confirm`)} ?
                            </div>
                            <div className="modal-footer">
                                <button type="button"
                                    className="btn btn-primary btn-sm"
                                    onClick={(el) => updateStatus(el, header)}
                                    data-bs-dismiss="modal"
                                >
                                    {t('Confirm')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='fixed-top disabled-print' style={{ left: '5%', top: '2%' }}>
                <FontAwesomeIcon icon={faCircleChevronLeft} onClick={goBack} className="text-primary" style={{ fontSize: 20 }} />
            </div>
            <h3 className='font-font-weight-bold text-center mt-2'>{t('SaleQuotation')}</h3>
            <div className="row mt-5">
                <div className="col-3 text-center">
                    <img src={`/assets/images/${company.img}`} alt="thammasak-icon" className='img-fluid' minwidth={100} maxwidth={150} />
                </div>
                <div className="col">
                    <div className="row">
                        <div className="col font-11">
                            {company.name}
                        </div>
                        <div className="col font-9">
                            website: {company.website}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col font-11">
                            {company.name_en}
                        </div>
                        <div className="col font-9">
                            email: {company.data?.email}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div style={{ borderTop: '1px solid #ccc', width: '100%', height: 1 }}></div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col font-9">
                            {company.address}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col font-9">
                            {company.data?.add_en}
                        </div>
                    </div>
                </div>
            </div>


            <div className="row mt-3">
                <div className="col">
                    <span className='font-11'>{t('Number')} </span>
                    <span className='font-font-weight-bold font-11'>
                        <b>{header?.document_no}</b>
                    </span>
                </div>
                <div className="col text-center font-11">{t('SaleQuotation')}</div>
                <div className="col text-end font-11">
                    {t('PageNo')} 1/1
                </div>
            </div>


            <div className='mt-2'>

                <div className="row font-11">
                    <div className="col-3 col-sm-2 text-end">
                        <span className=''>{t('customer')} :</span>
                    </div>
                    <div className="col p-0" style={{ borderBottom: "1px solid #ccc" }}>
                        <input
                            type="text"
                            name="cust_name"
                            value={header.cust_name || ''}
                            className="w-100 transparent-input"
                            onChange={(e) => changeHeader({ ...header, cust_name: e.target.value })}
                        />
                    </div>
                    <div className="col-4 col-sm-3">
                        <div className='row'>
                            <div className="col-7 text-end">
                                {t('rentDate')} :
                            </div>
                            <div className="col p-0">
                                <DatePicker
                                    selected={new Date(header?.start_date || null)}
                                    onChange={(date) => changeHeader({ ...header, start_date: moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ') })}
                                    dateFormat="dd/MM/yyyy"
                                    className="transparent-input w-100"
                                />
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row font-11">
                    <div className="col-3 col-sm-2 text-end">
                        <span className=''>{t('citizenId')} :</span>
                    </div>
                    <div className="col p-0" style={{ borderBottom: "1px solid #ccc" }}>
                        <input
                            type="text"
                            name="cust_cid"
                            onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) event.preventDefault(); }}
                            value={header.cust_cid || ''}
                            className="w-100 transparent-input"
                            onChange={(e) => changeHeader({ ...header, cust_cid: e.target.value })}
                        />
                    </div>
                    <div className="col-4 col-sm-3">
                        <div className='row'>
                            <div className="col-7 text-end">
                                {t('time')} :
                            </div>
                            <div className="col p-0 text-start">                                 
                                <input
                                    type="time"
                                    value={moment(header?.start_date).format('HH:mm') || ''}
                                    onChange={el => changeHeader({ ...header, start_date: global.changeTime(el, header?.start_date) }) }
                                    className="transparent-input w-100"
                                />
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row font-11">
                    <div className="col-3 col-sm-2 text-end">
                        <span className=''>{t('phone')} :</span>
                    </div>
                    <div className="col-5 col-sm-7 p-0" style={{ borderBottom: "1px solid #ccc" }}>
                        <input
                            type="text"
                            name="cust_mobile"
                            value={header.cust_mobile || ''}
                            className="w-100 transparent-input"
                            onChange={(e) => changeHeader({ ...header, cust_mobile: e.target.value })}
                        />
                    </div>
                    <div className="col text-start font-10 ">
                        {t('dateOfReturn')} :
                    </div>
                </div>


                <div className="row font-11">
                    <div className="col-3 col-sm-2 text-end">
                        <span className=''>{t('project/place')}  :</span>
                    </div>
                    <div className="col p-0" style={{ borderBottom: "1px solid #ccc" }}>
                        <input
                            type="text"
                            name="cust_place"
                            value={header.cust_place || ''}
                            className="w-100 transparent-input"
                            onChange={(e) => changeHeader({ ...header, cust_place: e.target.value })}
                        />
                    </div>
                    <div className="col-4 col-sm-3">
                        <div className='row'>
                            <div className="col-7 text-end">
                                {t('return date')} :
                            </div>
                            <div className="col p-0 text-start">
                                <DatePicker
                                    selected={new Date(header?.end_date || null)}
                                    onChange={(date) => changeHeader({ ...header, end_date: moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ') })}
                                    dateFormat="dd/MM/yyyy"
                                    className="transparent-input w-100"
                                />
                            </div>

                        </div>
                    </div>
                </div>


                <div className="row font-11">
                    <div className="col-3 col-sm-2 text-end">
                        <span className=''>{t('entity/place')} :</span>
                    </div>
                    <div className="col p-0" style={{ borderBottom: "1px solid #ccc" }}>
                        <input
                            type="text"
                            name="cust_project"
                            value={header.cust_project || ''}
                            className="w-100 transparent-input"
                            onChange={(e) => changeHeader({ ...header, cust_project: e.target.value })}
                        />
                    </div>
                    <div className="col-4 col-sm-3">
                        <div className='row'>
                            <div className="col-7 text-end">
                                {t('time')} :
                            </div>
                            <div className="col p-0 text-start">
                                <input
                                    type="time"
                                    value={moment(header?.end_date).format('HH:mm') || ''}
                                    onChange={el => changeHeader({ ...header, end_date: global.changeTime(el, header?.end_date) }) }
                                    className="transparent-input w-100"
                                />
                            </div>

                        </div>
                    </div>
                </div>


                <div className="row font-11">
                    <div className="col-3 col-sm-2 text-end">
                        <span className=''>{t('contact person')} :</span>
                    </div>
                    <div className="col-5 col-sm-7 p-0" style={{ borderBottom: "1px solid #ccc" }}>
                        <input
                            type="text"
                            name="cust_contact"
                            value={header.cust_contact || ''}
                            className="w-100 transparent-input"
                            onChange={(e) => changeHeader({ ...header, cust_contact: e.target.value })}
                        />
                    </div>
                    <div className="col-4 col-sm-3">
                        <div className='row'>
                            <div className="col-7 text-end font-10">
                                {t('all date rent')} :
                            </div>
                            <div className="col p-0 text-start">
                                <input
                                    type="text"
                                    name="total_amount"
                                    value={header.total_amount || 0}
                                    className="w-100 transparent-input"
                                    readOnly="readonly"
                                    onChange={(e) => changeHeader({ ...header, total_amount: e.target.value })}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-4'>
                <table className='table table-bordered font-10'>
                    <thead>
                        <tr>
                            <th width={'2%'} className="text-center">No.</th>
                            <th className="text-center">{t('Detail')}</th>
                            <th width={'15%'} className="text-center">{t('Qty')}</th>
                            <th width={'18%'} className="text-center">{t('Price/Unit')}</th>
                            <th width={'15%'} className="text-center">{t('date amount')}</th>
                            <th width={'16%'} className="text-center">{t('total (baht)')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {

                            order.line?.map((res, index) => {
                                let item = res.data
                                return (<tr key={index}>
                                    <td> {index + 1} </td>
                                    <td> {item.description || item.name} </td>
                                    <td className="text-end"> {parseFloat(item.date_amount)} </td>
                                    <td className="text-end"> {NumberFormat(item.price, 0)}</td>
                                    <td className="text-end"> {parseFloat(item.date_amount)}</td>
                                    <td className="text-end"> {NumberFormat(item.price * item.date_amount, 0)} </td>

                                </tr>)
                            })
                        }


                    </tbody>
                </table>
            </div>

            <div className='mt-3 '>
                <div className="row">
                    <div className="col">
                        {t('condition')}
                        <div className='font-12' >
                            <div>
                                <div className='text-danger'>
                                    • ราคาที่เสนอพร้อมคนขับและน้ำมัน
                                </div>
                                <div className='text-danger'>
                                    เริ่มเวลาทำงานตั้งแต่ 8:00น.-17:00น.
                                </div>
                                <div className='text-danger'>
                                    ถ้าเลยเวลาจะคิดเป็นชั่วโมงละ 500 บาท
                                </div>
                            </div>
                            <div>
                                <div className='text-danger'>
                                    • โอนเงินเข้าบัญชี {company.name}
                                    ธนาคารกรุงเทพ เลขที่บัญชี 101-3-47553-6 สาขา สำนักงานใหญ่สีลม
                                    {company.data?.bank}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-7 col-sm-6 font-12">
                        {t('total rent')}
                        <table className='table table-bordered border-red'>
                            <tbody>
                                <tr>
                                    <td className='text-end'>
                                        ยอดรวม (บาท)
                                    </td>
                                    <td className='text-end'>
                                        {NumberFormat(lineTotal)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-end'>
                                        ส่วนลดพิเศษ (บาท)
                                    </td>
                                    <td className='text-end'>
                                        {NumberFormat(order.header?.discount)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-end'>
                                        ยอดหลังหักส่วนลด (บาท)
                                    </td>
                                    <td className='text-end'>
                                        {NumberFormat(lineTotal - order.header?.discount)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-end'>
                                        ภาษีมูลค่าเพิ่ม 7 % (บาท)
                                    </td>
                                    <td className='text-end'>
                                        {NumberFormat(vat)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-end'>
                                        รวมยอดทั้งหมด (บาท)
                                    </td>
                                    <td className='text-end'>
                                        {NumberFormat(total)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className="row font-10 ">
                    <div className="col text-center py-2">
                        <div className='row py-3 no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative'>
                            <div className='text-center'>ผู้ตอบรับ</div>
                            <div className='row'>
                                <div className="col-4">
                                    ลงชื่อ :
                                </div>
                                <div className="col p-0">
                                    <div style={{ borderBottom: '1px solid #ccc', width: '100%', height: 20 }}></div>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className="col-4 ">
                                    {t('date')}
                                </div>
                                <div className="col p-0">
                                    <div style={{ borderBottom: '1px solid #ccc', width: '100%', height: 20 }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col  ">
                        <div className='d-flex justify-content-center mt-3'>
                            <p className=''>  ขอแสดงความนับถือ </p> <br />
                        </div>
                        <div className='d-flex justify-content-center'>
                            <div className='mt-2'>{"(......................................................................................)"}</div>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <div>{`(${company.data?.signer})`}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='d-flex justify-content-center ' style={{ marginBottom: 90 }}>
                <button className='btn btn-success btn-sm mx-1 disabled-print' onClick={print}>
                    <FontAwesomeIcon icon={faPrint} /> Print ใบเสนอราคา
                </button>
                <button
                    className='btn btn-primary btn-sm mx-1 disabled-print'
                    data-bs-toggle="modal"
                    data-bs-target="#confirmModal"
                    onClick={(element) => confirmOrder(element, order)}>
                    <FontAwesomeIcon icon={faCheckCircle} /> ยืนยันการเช่า
                </button>
            </div>

            <MenuMobile />
        </div>
    );
}

export default Quotations;
