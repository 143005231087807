import React from 'react';
import SideBar from '../component/sidebar'
import Header from '../component/header';
import MenuMobile from '../component/menubar';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Privilage = (props) => {
    const { t } = useTranslation();

    let { id, name } = useParams();
    console.log('name::', name, ', id:', id)

    return (
        <div className="container">
            <Header />
            <div className='row main products'>
                <div className='col-md-3'>
                    <SideBar />
                </div>
                <div className='col '>
                    <div className='container'>
                        <h3><span style={{ color: '#A96E54' }}>R</span>ental</h3>
                        {t('Privilage')}
                    </div>
                </div>
            </div>
            <MenuMobile />
        </div>
    );
}

export default Privilage;
