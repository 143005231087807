import uuid from 'react-uuid';
import jwt_decode from "jwt-decode";
import { decode as base64_decode, encode as base64_encode } from 'base-64';



const axios = require('axios').default;
const config = require(`../config/${process.env.NODE_ENV}`).default;


const client = axios.create({
    baseURL: config.api
});
client.interceptors.request.use(
    async config => {
        if (localStorage.getItem('token')) {
            config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        } else {
            if (!localStorage.getItem('uuid')) {
                let res = await fetch(config.baseURL + `/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ "username": 'guest', "password": '11111' })
                }).then((response) => response.json()).then((data) => data);

                localStorage.setItem('token', res.token);
                localStorage.setItem('uuid', base64_encode(JSON.stringify({ uuid: uuid(), user: jwt_decode(res.token)['id'] })));
                config.headers['Authorization'] = `Bearer ${res.token}`;
                console.log('config.headers::', res)
            }
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

client.interceptors.response.use((response) => {
    return response;
}, (error) => {
    console.error('error.response.status::', error.response)
    if (error.response.status === 401) {
        localStorage.removeItem('token')
    }
    return Promise.reject(error.response);
});


export default {
    client
};