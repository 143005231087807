import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import global from '../api/global';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
const moment = require('moment');

function TableOrders(props) {
    const { t } = useTranslation();
    const NumberFormat = global.NumberFormat;
    const JobStatus = global.JobStatus;

    return (
        <table className='table table-bordered font-10'>
            <thead>
                <tr>
                    <th width={'2%'} className="text-center">No.</th>
                    <th width={'16%'} className="text-center">{t('Date')}</th>
                    <th className="text-center">{t('Document No')}</th>
                    <th className="text-center">{t('Status')}</th>
                    <th className="text-center">{t('Action')}</th>
                </tr>
            </thead>
            <tbody>
                {
                    props.orders.length > 0 && props.orders?.map((item, index) => {
                        return (<tr key={item.id}>
                            <td> {index + 1} </td>
                            <td className="text-end"> {moment(item.order_date).format('DD/MM/YY')} </td>
                            <td> {item.document_no} </td>
                            <td> {JobStatus(item.status)} </td>
                            <td className="text-center">
                                <Link to={`/order/${item.id}`} className="text-decoration-none" ><FontAwesomeIcon icon={faEye} /> {t('Views')} </Link>
                            </td>
                        </tr>)
                    })
                }
            </tbody>
        </table>
    )
}

export default TableOrders;