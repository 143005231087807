import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { decode as base64_decode, encode as base64_encode } from 'base-64';

import SideBar from '../component/sidebar'
import MenuMobile from '../component/menubar';
import TopMenu from '../component/top-menu';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons'
import apiCart from '../api/cart';
import TemplateCart from '../component/template-cart';
import Waiting from '../component/waiting';
import { useNavigate } from 'react-router-dom';
import global from '../api/global';

const Cart = () => {
    const { t } = useTranslation();
    const NumberFormat = global.NumberFormat;
    const [loading, setLoading] = useState(false);
    const [orderId, setOrderId] = useState('');
    const [cartList, setCartList] = useState({});
    const [dataRemove, setDataRemove] = useState([]);
    const [itemCart, setItemCart] = useState([]);
    const [deposit, setDeposit] = useState(0);
    const [priceRent, setPriceRent] = useState('');

    const [lineTotal, setLineTotal] = useState(0);
    const [vat, setVat] = useState('');
    const [total, setTotal] = useState('');
    const navigate = useNavigate();



    const updateLine = async () => {
        let cacheOrder = localStorage.getItem("orderCache") ? JSON.parse(base64_decode(localStorage.getItem("orderCache"))) : [];
        //console.log('update line::', cacheOrder);
    }

    const fetchData = async () => {
        let cartList = localStorage.getItem("cart-list") ? JSON.parse(localStorage.getItem("cart-list")) : [];

        setItemCart(cartList);


        let lineAmount = 0;
        cartList?.forEach(item => {
            //console.log('date_amount::', item.date_amount)
            if (item.checked) {
                lineAmount += item.price * item.date_amount;
            }
        })
        setLineTotal(lineAmount);

        /**
         * Total
         */
        let vat7 = Number(lineAmount) * 7 / 100;
        let totals = Number(lineAmount + vat7);

        setVat(vat7);
        setTotal(totals);
    }

    useEffect(() => {
        localStorage.setItem('link-cart', 'd-none');
        fetchData().catch(console.error);
    }, [cartList])


    const removeItemCart = async (el, item) => {
        //console.log('item::', item);
        let cart = JSON.parse(localStorage.getItem('cart-list'));
        let afterDelete = cart.filter(el => el.uuid !== item.uuid)
        setItemCart(afterDelete);
        localStorage.setItem('cart-list', JSON.stringify(afterDelete));

        //let cart = await apiCart.deleteLine(item.id, item.orders);
        //console.log('after delete carts::', cart)
        //setItemCart(cart.line);
    }

    const confirmDelete = (el, item) => {
        setDataRemove(item);
    }

    const checkedClick = async (event) => {
        /**get cart list */
        let cartList = JSON.parse(localStorage.getItem('cart-list')) || [];

        if (event.target.checked) {
            cartList.forEach(element => {
                if (element.uuid === event.target.id) {
                    element.checked = true;

                }
            });
            //console.log('cartList checked::', cartList);
            /**make json data from checked */
            localStorage.setItem('cart-list', JSON.stringify(cartList))
        } else {
            cartList.forEach(element => {
                if (element.uuid === event.target.id) {
                    element.checked = false;
                }
            });
            localStorage.setItem('cart-list', JSON.stringify(cartList))
        }

        setCartList(cartList);
    };


    const createOrder = async () => {

        try {
            let orderLine = JSON.parse(localStorage.getItem('cart-list'));
            console.log('>>> POST Create LINE::', orderLine)
            let response = await apiCart.addCartList(orderLine);

            localStorage.setItem('order', JSON.stringify(response))
            console.log('<<< Create LINE::', response);
            if (response.header?.id > 0) {
                navigate(`/quotation/${response.header?.id}`);
            }
        } catch (e) {
            console.error('error::', e);
        }

        /**Clear data */
        //localStorage.removeItem('cart-list')
    }

    return (
        <div className=" ">
            <Waiting loading={loading} />
            <TopMenu />
            {/* row main products */}
            <div className={`container  `} >
                <div className='row main products'>
                    <div className='col-md-3'>
                        <SideBar />
                    </div>
                    <div className='col'>
                        <div className='container'>
                            {itemCart.length > 0 && itemCart?.map((item, index) => (
                                <div key={item.uuid} >
                                    <TemplateCart.Template
                                        item={item}
                                        updateLine={updateLine}
                                        checkedClick={checkedClick}
                                        setCartList={setCartList}
                                        itemCart={itemCart}
                                    />
                                    <div className="text-end">
                                        <button
                                            className='btn btn-link text-end'
                                            style={{ marginRight: '5%' }}
                                            onClick={(event) => confirmDelete(event, item)}
                                            data-bs-toggle="modal"
                                            data-bs-target="#confirmModal"
                                        >
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                        </button>
                                        <hr />
                                    </div>
                                </div>
                            ))}


                            {
                                itemCart.length > 0
                                    ? <div className='font-12'>

                                        <div className='row'>
                                            <div className='col-6'>ค่าประกัน</div>
                                            <div className='col-4 text-end'>{NumberFormat(deposit)}</div>
                                            <div className='col-2'>{t('Baht')}</div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-6'>ราคาเช่าทุกรายการ</div>
                                            <div className='col-4 text-end'>{NumberFormat(lineTotal)}</div>
                                            <div className='col-2'>{t('Baht')}</div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-6'>ภาษีมูลค่าเพิ่ม</div>
                                            <div className='col-4 text-end'>{NumberFormat(vat)}</div>
                                            <div className='col-2'>{t('Baht')}</div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-6 text-info'>ยอดชำระทั้งหมด</div>
                                            <div className='col-4 text-end'>{NumberFormat(total)}</div>
                                            <div className='col-2'>{t('Baht')}</div>
                                        </div>
                                        <div className='row'>
                                            <div className='col my-2'>
                                                <button onClick={createOrder} className="btn btn-success btn-sm">
                                                    บันทึกรายการ
                                                </button>

                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                หรือสามารถดำเนินการชำระได้ดังนี้
                                            </div>
                                        </div>
                                        <div className='row my-3'>
                                            <div className='col text-end'>
                                                <button className="btn btn-primary btn-sm">รับเองที่ร้าน</button>
                                                <button className="btn btn-primary btn-sm ms-2">จัดส่งตามระยะทาง</button>
                                            </div>
                                        </div>
                                    </div>
                                    : <div className='d-flex flex-column'>
                                        <div className='align-self-center'>{t('There are no items in this cart')}</div>
                                        <div className='align-self-center mt-2'>
                                            <Link to={'/'} type="button" className='btn btn-outline-warning'>{t('CONTINUE SHOPPING')}</Link>
                                        </div>
                                    </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
            <MenuMobile />
            <div className="modal fade" id="confirmModal" tabIndex={-1} aria-labelledby="confirmModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="confirmModalLabel">Confirm</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            {t(`Delete`, { name: dataRemove.name })}
                        </div>
                        <div className="modal-footer">
                            <button type="button"
                                className="btn btn-danger"
                                onClick={(el) => removeItemCart(el, dataRemove)}
                                data-bs-dismiss="modal"
                            >
                                {t('Confirm')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cart;
