import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faGear
} from '@fortawesome/free-solid-svg-icons'


function MenuLeft() {
    const { t } = useTranslation();
    const [fade, setFade] = useState('');

    const HandleMenuLeft = () => {
        console.log('show')
        setFade('fadeIn')

    }

    const CloseMenu = () => {
        console.log('hide')
        setFade('fadeOut')
    }

    useEffect(() => {
        //fetchData().catch(console.error);

    })



    return (
        <div className=''>
            <div className='row' style={{ backgroundColor: "#fff", height: 150 }}>
                <div className="col">
                    <div className='d-flex justify-content-center  d-block d-sm-none'>
                        <img src="/assets/images/login-logo.png" alt="logo" className='img-fluid shadow-logo' />
                    </div>
                    <div className=" ">
                        <div className='row'>
                            <div className='col'>
                                <FontAwesomeIcon icon={faGear} onClick={HandleMenuLeft} className="pointer" />
                            </div>
                            <h5 >{t('หน้าหลักรายการเช่า')}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`menu-left ${fade}`} onClick={CloseMenu}>
                <div className='d-flex justify-content-center'>
                    <img src="/assets/images/login-logo.png" alt="logo" className='img-fluid mt-3 main-logo' style={{ width: '30%' }} />
                </div>
                <ul className="nav  flex-column fs-6">
                    <li className="nav-item d-flex justify-content-center mt-5" >
                        <NavLink to="/" className="nav-link" >
                            <span className="m-2 text-white">{t('first_page')}</span>
                        </NavLink>
                    </li>

                    <li className="nav-item d-flex justify-content-center mt-2">
                        <NavLink to="/profile" className="nav-link" >
                            <span className="m-2 text-white">{t('user_info')}</span>
                        </NavLink>
                    </li>

                    <li className="nav-item d-flex justify-content-center mt-2">
                        <NavLink to="/payment" className="nav-link" >
                            <span className="m-2 text-white">{t('payment_method')}</span>
                        </NavLink>
                    </li>

                    <li className="nav-item d-flex justify-content-center mt-2">
                        <NavLink to="/cart" className="nav-link" >
                            <span className="m-2 text-white">{t('item_cart')}</span>
                        </NavLink>
                    </li>

                    <li className="nav-item d-flex justify-content-center mt-2">
                        <NavLink to="/orders" className="nav-link" >
                            <span className="m-2 text-white">{t('job_status')}</span>
                        </NavLink>
                    </li>

                    <li className="nav-item d-flex justify-content-center mt-2">
                        <NavLink to="/privilege" className="nav-link" >
                            <span className="m-2 text-white">{t('privilege_for_you')}</span>
                        </NavLink>
                    </li>

                    <li className="nav-item d-flex justify-content-center mt-2">
                        <NavLink to="/about" className="nav-link" >
                            <span className="m-2 text-white">{t('about_us')}</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default MenuLeft;
