import { t } from "i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendarAlt
} from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from "react";
import global from "../api/global";

const Template2 = ({ item: data, updateLine, checkedClick, setCartList, itemCart }) => {
    const [item, setItems] = useState([]);


    const handleChange = async (event) => {
        let newBody = {
            ...item,
            [event.target.name]: event.target.value
        }
        setItems(newBody);

        itemCart.map(el => {
            if (el.uuid === item.uuid) {
                el.date_amount = parseFloat(event.target.value);
            }
        })
        /**
         * Update item to parent
         */
        setCartList(itemCart);
        localStorage.setItem('cart-list', JSON.stringify(itemCart));
        updateLine()
    };

    const NumberFormat = (value) => Number(value).toLocaleString(navigator.language, { minimumFractionDigits: 2 });

    const clickCheckBox = (event) => {
        /** refresh data after click checkbox */
        setItems({
            ...item,
            checked: event.target.checked
        });
        return checkedClick(event)
    }

    useEffect(() => {
        setItems(data);
    }, [])

    console.log('item::', item);
    return (
        <div className="mb-2 font-12">
            <div className="row  mb-1">
                <div className='col-4 '>
                    <label htmlFor={item.id}>
                        <img
                            src={item.img}
                            alt={item.title}
                            className="img-fluid"
                            style={{ maxWidth: '100%' }}
                        />
                    </label>
                </div>
                <div className="col">
                    <div className="row">
                        <div className="col">
                            <input
                                className='form-check-input checkbox-lg'
                                type={'checkbox'}
                                name={item.name}
                                checked={item.checked || false}
                                onChange={clickCheckBox}
                                id={item.uuid}
                            />

                            <label className="ms-1" htmlFor={item.uuid}>{item.title} {item.description || item.name}</label>
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="col">
                            {item.detail}
                        </div>
                    </div>

                     

                    <div className="row my-1">
                        <div className="col">
                            <span className="ms-1">{t('start price')}</span>
                        </div>
                        <div className="col-4 text-end">
                        {Number(item.date_amount * item.price || 0)}
                        </div>
                        <div className="col-3">
                            {t('baht/day')}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}


export default Template2;