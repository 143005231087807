import React, { useEffect, useState } from 'react';
import SideBar from '../component/sidebar'
import MenuMobile from '../component/menubar';
import TopMenu from '../component/top-menu';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import Product from '../api/products';
import Cart from "../api/cart";
import moment from 'moment/moment';
import uuid from 'react-uuid';
import global from '../api/global';

const Products = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [checkedItems, setCheckedItems] = useState({});
    const [products, setProducts] = useState(null);
    const [order, setOrder] = useState();
    const [groupData, setGroupData] = useState();
    const [renderTemplate, setRenderTemplate] = useState();
    const [productName, setProductName] = useState();
    const [preItem, setPreItem] = useState([]);
    const { id } = useParams();

    /**onload */
    useEffect(() => {
        //localStorage.removeItem('cart-list')
        const fetchData = async () => {
            let product = await Product.getProductByCat(id);
            let newProduct = [];
            product.forEach(el => {
                let img = el.img?.split('.') || ['404', 'jpg'];
                newProduct.push({
                    ...el,
                    prices: typeof JSON.parse(el.prices) === 'string' ? JSON.parse(JSON.parse(el.prices)) : JSON.parse(el.prices),
                    img: `${global.config.api}/images/items/${img[0]}/${img[1] || 'jpg'}/small`
                })
            })


            let data = global.groupBy(newProduct, item => item.name);
            //console.log('data group::', data)
            setGroupData(data);

            let dataProductName = [];
            data.forEach((value, key) => {
                console.log(`${key} = ${JSON.stringify(value)}`);
                dataProductName.push(key)
            });

            setProductName(dataProductName);

            //console.log('data::', data.get());

            setProducts(newProduct);

        }
        fetchData().catch(console.error);
    }, [])




    if (localStorage.getItem('cart-list')) {
        /**show btn */
        localStorage.setItem('link-cart', 'd-block')
    }


    const Checkbox = ({ type = "checkbox", name, checked = false, onChange, id }) => {
        return (
            <input className='form-check-input checkbox-lg' type={type} name={name} checked={checked} onChange={onChange} id={id} />
        );
    };

    const handleChange = async (event) => {

        setCheckedItems({
            ...checkedItems,
            [event.target.id]: event.target.checked
        });

        /**get cart list */
        let cartList = JSON.parse(localStorage.getItem('cart-list')) || [];


        /**search data in product list */
        let newData = products.filter(el => event.target.checked && el.id === parseInt(event.target.id));

        const itemExists = (id) => {
            return cartList.some((el) => {
                return el.id === parseInt(id);
            });
        }

        /**update amount*/
        if (event.target.checked) {
            newData[0].date_amount = 1;
            newData[0].checked = true;
            newData[0].uuid = uuid();
            //cartList.push(newData[0]);
            /**make json data from checked */

            //localStorage.setItem('cart-list', JSON.stringify(cartList))

            preItem.push(newData[0])
            setPreItem(preItem)
        } else {
            //let newData = cartList.filter(el => el.id !== parseInt(event.target.id))
            /**make json data after unchecked */
            //localStorage.setItem('cart-list', JSON.stringify(newData))
            let newData = preItem.filter(el => el.id !== parseInt(event.target.id))
            setPreItem(newData)
        }

        if (event.target.checked && itemExists(event.target.id)) {

        }

    };



    const addToCart = async () => {
        /**hidden button */
        localStorage.setItem('link-cart', 'd-none')

        try {
            let items = JSON.parse(localStorage.getItem('cart-list'));
            //console.log('cart lists::', items)

            
            /**
             * Add bom into cart
             */
            

            
            let newItem = items.concat(preItem)
            //console.log('new data::', newItem)




            /**
             * Update cart
             */
            localStorage.setItem('cart-list', JSON.stringify(newItem))
            navigate('/cart')
        } catch (e) {
            console.error('error::', e);
        }

    }


    console.log('productName::', productName);

    //console.log('products::', products)
    return (
        <div className=" ">
            <TopMenu addToCart={addToCart} />
            <div className="container">
                <div className='row main products '>
                    <div className='col-md-3'>
                        <SideBar />
                    </div>
                    <div className='col'>
                        <div className='container'>

                            {
                                productName?.map((name, index) => (
                                    <div key={index}>

                                        <div className="d-flex mb-2 checkbox" key={index}>
                                            {
                                                <Checkbox
                                                    name={groupData.get(name)[0].name}
                                                    checked={checkedItems[groupData.get(name)[0].id]}
                                                    onChange={handleChange}
                                                    id={groupData.get(name)[0].id}
                                                />

                                            }


                                            <label className='ms-2 d-flex' htmlFor={groupData.get(name)[0].id}>
                                                <div className='shadow-item d-flex align-items-center items-list' >
                                                    <img src={groupData.get(name)[0].img} alt={groupData.get(name)[0].name} className="img-fluid " />

                                                </div>
                                                <div className='ms-2'>
                                                    <div className="font-12"><b>{name}</b></div>
                                                    <div className="font-11">{groupData.get(name)[0].detail}</div>
                                                    <div className='row'>
                                                        <div className='col-3 font-11' style={{ minWidth: 60 }}>
                                                            {t('prices')}
                                                        </div>
                                                        <div className="col font-10">

                                                            {groupData.get(name)[0].prices?.day ? <div>: {groupData.get(name)[0].prices?.day} <span style={{ fontSize: 9 }}>{t('baht/day')}</span></div> : ''}
                                                            {groupData.get(name)[0].prices?.month ? <div>: {groupData.get(name)[0].prices?.month} <span style={{ fontSize: 9 }}>{t('baht/month')}</span></div> : ''}
                                                            {groupData.get(name)[0].prices?.years ? <div>: {groupData.get(name)[0].prices?.years} <span style={{ fontSize: 9 }}>{t('baht/years')}</span></div> : ''}

                                                        </div>
                                                    </div>
                                                </div>
                                            </label >
                                        </div >
                                        <div className='mb-5'>
                                            {groupData.get(name).length > 1 && groupData.get(name).map(item => (
                                                <div className="ms-5 mt-1" key={item.id}>
                                                    <Checkbox
                                                        name={item.description}
                                                        checked={checkedItems[item.id]}
                                                        onChange={handleChange}
                                                        id={item.id}
                                                    />
                                                    <label className='ms-2 pointer' htmlFor={item.id} >{item.description || name}</label>
                                                </div >
                                            ))}
                                        </div>
                                    </div>
                                ))
                            }


                        </div>
                    </div>
                </div>
            </div>
            <MenuMobile />
        </div>
    );
}

export default Products;
