import { Link, NavLink } from "react-router-dom";
import SwitchLang from './language';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'; 
const onEnter = (el) => {
    if (el.key === 'Enter') {
        el.preventDefault();
        let value = el.target.value;
        console.log(value)

        // set null
        el.target.value = '';
    }
}

function Header() {
    const { t } = useTranslation(); 
    return (
        <header>
            <img src="/assets/images/thammasak-w.png" className='d-block d-sm-none mt-3' alt="thammasak" style={{ maxWidth: 200 }} />
            <nav className="navbar navbar-expand-md navbar-light fixed-top bg-light nav-header">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src="/assets/images/thammasak.png" className='img-fluid' alt="thammasak" style={{ maxWidth: 110 }} />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav me-auto mb-2 mb-md-0">
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="/">{t('_home')}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/cart">{t('_cart')}</Link>
                            </li>
                            
                        </ul>
                        <SwitchLang className="d-none" />
                        <form className="d-flex" role="search">
                            <div className="input-group flex-nowrap">
                                <input type="text"
                                    className="form-control "
                                    aria-describedby="addon-wrapping"
                                    onKeyDown={onEnter}
                                />
                                <span className="input-group-text" id="addon-wrapping"> <FontAwesomeIcon icon={faSearch} /></span>
                            </div>
                        </form>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header;