import { decode as base64_decode, encode as base64_encode } from 'base-64';
import moment from 'moment/moment';
import apiUser from './user';

const { client } = require('./auth').default;

const getCartList = async (user) => {
    try {
        const response = await client.get(`/v1/orders/${user}/list`);
        console.log('orders respose::', response)
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

const getCartById = async (id) => {
    try {
        let orderHeader = await client.get(`/v1/orders/${id}`);
        let orderLine = await client.get(`/v1/orders/line/${id}`);
        return {
            header: orderHeader.data,
            line: orderLine.data
        };
    } catch (error) {
        console.error(error);
        return [];
    }
}

const addCart = async () => {
    try {
        let uuid = JSON.parse(base64_decode(localStorage.getItem('uuid')));
        let user = await apiUser.get(uuid.user);
        console.log('user::', user);
        user = {
            ...user,
            settings: typeof user.settings === 'string'
                ? JSON.parse(user.settings)
                : user.settings
        }
        console.log('user create header::', user);
        let body = {
            "user": uuid.user,
            "order_date": "2022-08-21T04:11:41.168Z",
            "items": "",
            "cost": 0,
            "amount": 0,
            "total_amount": 0,
            "discount": 0,
            "total_discount": 0,
            "cust_name": "",
            "cust_cid": "",
            "cust_mobile": user?.phone,
            "cust_project": "",
            "cust_place": "",
            "start_date": moment().format('YYYY-MM-DDThh:mm:ss.SSSZ').toString(),
            "end_date": moment().format('YYYY-MM-DDThh:mm:ss.SSSZ').toString(),
            "gps": "",
            "ref1": "",
            "ref2": "",
            "ref3": "",
            "status": 0,
            "deposit": 0
        }
        console.log('>>> CREATE HEADER', body)
        const response = await client.post(`/v1/orders`, body);
        console.log('<<< RES CREATE HEADER', response)
        return response.data;
    } catch (error) {
        console.error("Error create header::", error)
        console.error(error);
    }
}

const getCart = async (id) => {
    let data;
    try {
        if (id) {
            let response = await client.get(`/v1/orders/${id}`);
            if (response.data.id > 0) {
                /**
                 * Already has id 
                 */
                data = { data: response.data };
                console.log('use old cart header', data)
            } else {
                /**
                 * Create new header
                 */
                let createHeader = await addCart();
                data = createHeader;
                console.log('create new cart header', data)
            }
        } else {
            let createHeader = await addCart();
            data = createHeader;
            console.log('The id is empty, Create new cart header', data)
        }

        /**
         * Save to cache
         */
        localStorage.setItem("orderCache", base64_encode(JSON.stringify(data)));

        return data;
    } catch (e) {
        console.error('create header error::', e);
        return {
            status: 99,
            message: e.message || 'unknown error'
        }
    }
}

const addCartList = async (items) => {
    try {
        /**
         * 1. Check header
         * 2. If not have header, Create header first.
         * 3. Cleate Line
         */

        /**
         * Check Header
         */
        let cacheOrder = localStorage.getItem("orderCache") && base64_decode(localStorage.getItem("orderCache")) !== 'undefined'
            ? JSON.parse(base64_decode(localStorage.getItem("orderCache")))
            : [];
        console.log('cacheOrder::', cacheOrder);
        let header = await getCart(cacheOrder.data?.id)
        console.log('HEADER::', header)
        /**
         * Clear Line befor creat 
         */
        if (cacheOrder) {
            await client.delete(`/v1/orders/lines/${header.data?.id}`);
        }


        /**
         * Create Line
         * 
         */
        let body = [];
        items.forEach(item => {
            if (item.checked) {
                body.push({
                    orders: header.data?.id,
                    item: item.id,
                    qty: item.qty || 0,
                    unit: item.unit || 'unit',
                    name: item.description || item.name,
                    category: item.cat,
                    img: item.img,
                    price: item.price,
                    vat: item.vat || 0,
                    discount: item.discount || 0,
                    date_amount: item.date_amount,
                    start_date: moment().format('YYYY-MM-DDThh:mm:ss.SSSZ').toString(),
                    end_date: moment().format('YYYY-MM-DDThh:mm:ss.SSSZ').toString(),
                    setting: JSON.stringify({
                        bom: item.bom ,
                        prices: item.prices,
                        date_amount: 0
                    })

                });


            }
        });

        console.log('>>> POST', body);
        const response = await client.post(`/v1/orders/line`, body);
        console.log('<<< RES::', response);
        return {
            header: header.data,
            line: response.data
        };
    } catch (error) {
        console.error('error addCartList::', error);
    }
}

const confirm = async (params, id) => {
    try {

        let body = {
            ...params,
            status: 1
        }

        console.log('>>> PATCH CONFIRM', body);
        const response = await client.patch(`/v1/orders/${id}`, body);
        console.log('<<< CONFIRM RES::', response);
        return response.data;
    } catch (e) {
        console.error('error confirm::', e);
    }
}

const deleteLine = async (id, order) => {

    try {
        let response = await client.delete(`/v1/orders/line/${id}`);

        return await getCartById(order);
    } catch (error) {
        console.error(error);
    }
}

export default {
    getCartList,
    addCart,
    addCartList,
    getCartById,
    deleteLine,
    confirm
};