import moment from 'moment';
const config = require(`../config/${process.env.NODE_ENV}`).default;

const JobStatus = (status) => {
    let text = '';
    switch (status) {
        case 0:
            text = "Create";
            break;
        case 1:
            text = "Pending";
            break;
        case 2:
            text = "Confirmed";
            break;
        case 3:
            text = "Not paid";
            break;
        case 4:
            text = "Waiting";
            break;
        case 5:
            text = "paid";
            break;
        case 9:
            text = "canceled";
            break;
        default:
            text = "Not set"
            break;
    }

    return (
        <div>{text}</div>
    )
}

const NumberFormat = (value, digit = 2) => Number(value).toLocaleString(navigator.language, { minimumFractionDigits: digit });

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

const changeTime = (event, date) => {
    const time = event.target.value.split(':');
    let dateTime = moment(date).set({ h: time[0], m: time[1] }).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    console.log('date-time::', dateTime);
    return dateTime;
}

export default {
    JobStatus,
    NumberFormat,
    config,
    groupBy,
    changeTime
};