import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';


function TopMenu(props) {
    let { addToCart } = props;
    const navigate = useNavigate();
    let btnRent = localStorage.getItem('link-cart');

    const { t } = useTranslation();


    const backPage = () => navigate(-1);

    return (
        <div className="top-menu-height">
            <div className='position-fixed w-100  ' style={{ zIndex: 10, backgroundColor: '#fff' }}>
                <div className="container">
                    <div className='d-flex  mt-2 '>
                        <img src="/assets/images/login-logo.png" alt="logo" className='img-fluid shadow-logo' />
                    </div>
                    <div className="row top-menu">
                        <div className="col" style={{ minHeight: 32 }}>
                            <div className={`d-flex justify-content-end`} >
                                <div className={`btn btn-success font-11  ${btnRent}`} onClick={addToCart} aria-current="page" >
                                    {t('shoot_selected')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <h4 className="mt-2 ">
                                <Link to="/" className="nav-link active pointer " style={{ fontSize: 15 }} aria-current="page" >
                                    <FontAwesomeIcon icon={faChevronLeft} /><b> <span className="ms-1">{t('back')}</span></b>
                                </Link>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopMenu;