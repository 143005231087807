const loading = (props) => {
    let loading = props.loading;
    return (
        <div className="">
            {loading
                ? <div className="loading-box">
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                </div>
                : ''}
        </div>
    )
}

export default loading;