import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Waiting from '../component/waiting';
import { useNavigate } from 'react-router-dom';


const Signout = () => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();


    useEffect(() => {
        localStorage.removeItem('uuid');
        localStorage.removeItem('token');
        localStorage.removeItem('cart-list');
        localStorage.removeItem('orderCache');
        localStorage.removeItem('userInfo');
        setTimeout(() => {
            navigate('/login', { replace: true });
        })
    }, [])

    return (
        <div className='container'>
            <Waiting loading={loading} />
        </div>
    );
}

export default Signout;
