import React, { useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import {
    faHome,
    faGrip,
    faCartPlus
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';
import apiUser from '../api/user';



export default function SideBar() {
    const { t } = useTranslation();
    const [user, setUser] = useState('');
    let uuid = localStorage.getItem('uuid') ? JSON.parse(base64_decode(localStorage.getItem('uuid'))) : [];

    const fetchData = async () => {
        let userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : [];
        setUser(userInfo);
        if (!uuid) {
            let getUser = await apiUser.get(uuid.user);
            setUser(getUser);
        }
    }

    useEffect(() => {
        fetchData().catch(console.error);
        window.addEventListener('storage', fetchData)
        return () => { window.removeEventListener('storage', fetchData) }
    }, []);
 
    return (

        <div className="d-flex flex-column flex-shrink-0 p-3 custom-themes d-none d-md-block" style={{ minWidth: 180 }}>

            <ul className="nav nav-pills flex-column mb-auto">
                <li className="nav-item">
                    <NavLink to="/" className="nav-link" >
                        <FontAwesomeIcon icon={faHome} />
                        <span className="m-2">{t('_home')}</span>
                    </NavLink>
                </li>

                <li className="nav-item">
                    <NavLink to="/cart" className="nav-link" >
                        <FontAwesomeIcon icon={faCartPlus} />
                        <span className="m-2">{t('cart')}</span>
                    </NavLink>
                </li>
            </ul>
            <hr />
            {uuid.user !== 2
                ? <div className="dropdown">
                    <Link to="/" className="d-flex align-items-center link-dark text-decoration-none dropdown-toggle" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="/assets/images/98681.jfif" alt="" width="32" height="32" className="rounded-circle me-2" />
                        <strong>{user?.f_name_th}</strong>
                    </Link>
                    <ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
                        <li><Link to="/cart" className="dropdown-item" ><FontAwesomeIcon icon={faCartPlus} /> {t('cart')}</Link></li>
                        {/* <li><Link to="/settings" className="dropdown-item" >Settings</Link></li> */}
                        <li><Link to="/profile" className="dropdown-item" >Profile</Link></li>
                        <li><hr className="dropdown-divider" /></li>
                        <li><Link to="/signout" className="dropdown-item" >Sign out</Link></li>
                    </ul>
                </div>
                : ""
            }

        </div>

    )
}

