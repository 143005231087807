import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Product from '../api/products';
import global from '../api/global';

function Items() {
    const [category, setCategory] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            let cat = await Product.getCateoryList();
            let newCat = [];
            cat.forEach(el => {
                let img = el.img.split('.');
                newCat.push({
                    ...el,
                    list: JSON.parse(el.list),
                    img: `${global.config.api}/images/category/${img[0]}/${img[1]}/small`
                })
            })
            setCategory(newCat);
        }
        fetchData().catch(console.error);
    }, []);

    return (
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3 category">
            {
                category?.map(item => {
                    return (
                        <Link to={`/products/${item.id}/${item.name}`} className="col text-decoration-none text-reset" key={item.id}>
                            <div className="items">
                                <div className='row'>
                                    <div className='cart col col-sm-12 item-img bg-white d-flex align-items-center'>
                                        <img src={item.img} alt={item.alt} className='img-fluid' />
                                    </div>
                                    <div className="col col-sm-12 item-description">
                                        <h6>{item.name}</h6>
                                        <p className='cart-title'>{item.title}</p>
                                        <p className="cart-desc">{item.description}</p>
                                        <div className='row cart-price font-12'>
                                            {item.list.map((el, i) => <li key={i}>{el}</li>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    )
                })
            }

        </div>
    )
}

export default Items;